const { post, get } = require("../utils/rest");
const SERVICE = require("./constants");

module.exports = class BusinessAPI {
  constructor(businessId) {
    this.businessId = businessId || process.env.GATSBY_BUSINESS_ID;
  }

  getBusiness() {
    return get(
      `${SERVICE.BUSINESS}/getBusiness?id=${this.businessId}&platform=${
        SERVICE.PLATFORM_WEB
      }`,
    );
  }

  getBranches() {
    return get(
      `${SERVICE.BUSINESS}/getAllBranches/businessId=${
        this.businessId
      }?imagePreview=true`,
    );
  }

  getOrderFixedRemarks() {
    return get(
      `${SERVICE.ORDER}/getFixedRemarks/businessId=${this.businessId}`,
    );
  }

  getBranchesAvailability() {
    return get(
      `${SERVICE.BUSINESS}/getBranchesAvailability?businessId=${
        this.businessId
      }`,
    );
  }

  getDeliveryPolicies() {
    return get(
      `${SERVICE.ORDER}/getDeliveryPoliciesForBusiness?businessId=${
        this.businessId
      }`,
    );
  }

  getDeliveryOptions({
    geoPoint,
    deliveryAddress,
    servingOptionId,
    branchId,
    orderAmount,
    enableMultipleOptionsForBranch,
    minimumAvailability,
  }) {
    return post(`${SERVICE.ORDER}/getDeliveryOptions`, {
      businessId: this.businessId,
      geoPoint,
      deliveryAddress,
      servingOptionId,
      branchId,
      orderAmount,
      enableMultipleOptionsForBranch,
      minimumAvailability: "AVAILABLE_LATER",
    });
  }

  getCurrency() {
    return get(`${SERVICE.BUSINESS}/getCurrency/businessId=${this.businessId}`);
  }

  getBusinessAppConfiguration() {
    return get(
      `${SERVICE.BUSINESS}/getBusinessAppConfiguration/userId=none&businessId=${
        this.businessId
      }&os=web`,
    );
  }
  // getWebAppStyles() {
  //   return get(`${SERVICE.BUSINESS}/getWebAppStyles/businessId=${this.businessId}&os=web`);
  // }

  getServingOptions() {
    return get(
      `${SERVICE.ORDER}/getServingOptions/businessId=${this.businessId}`,
    );
  }

  getMenus() {
    return get(
      `${SERVICE.MENU}/getPickupMenuForBusiness/businessId=${this.businessId}`,
    );
  }

  getMenuCategories(menuId) {
    return get(
      `${
        SERVICE.MENU
      }/getAllCategoriesForMenu/menuId=${menuId}?imagePreview=true`,
    );
  }

  getItemsForMenuCategory(categoryId) {
    return get(
      `${
        SERVICE.MENU
      }/getAllItemsForMenuCategory?categoryId=${categoryId}&imagePreview=true`,
    );
  }

  getMenuItemOrderData(menuItemId) {
    return get(
      `${
        SERVICE.ORDER
      }/getMenuItemOrderDataByMenuItemId/menuItemId=${menuItemId}`,
    );
  }

  getMenuMultipliers() {
    return get(
      `${SERVICE.ORDER}/getOptionMultipliers/businessId=${this.businessId}`,
    );
  }

  // deactivateAllCouponsForUser() {
  //   return post(`${SERVICE.COUPON}/deactivateAllCouponsForUser/businessId=${this.businessId}`);
  // }

  getPaymentType() {
    return get(
      `${SERVICE.PAYMENT}/getBusinessPaymentType?businessId=${this.businessId}`,
    );
  }

  getMessages(language) {
    return get(
      `${
        SERVICE.API_ROOT
      }/TranslationService/getTranslationsDictionary/businessId=${
        this.businessId
      }&os=web`,
    ).then((allLanguageMessages) => allLanguageMessages[language]);
  }

  getAllDeals() {
    return get(
      `${SERVICE.ORDER}/getAllDiscountRules?businessId=${
        this.businessId
      }&filterVisible=true&imagePreview=true`,
    );
  }

  getAllCharities() {
    return get(
      `${SERVICE.BUSINESS}/getCharitiesForBusiness/businessId=${
        this.businessId
      }?imagePreview=true`,
    );
  }

  getPrivacyConsentDetails() {
    return get(
      `${SERVICE.BUSINESS}/getPrivacyConsentDetails?businessId=${
        this.businessId
      }`,
    );
  }

  getRegistrationGiftsForBranch(branchId) {
    return get(
      `${SERVICE.COUPON}/getCouponPoliciesForRegistrationEvent?businessId=${
        this.businessId
      }&branchId=${branchId}&platform=${SERVICE.PLATFORM_IN_STORE_SIGNUP}`,
    );
  }

  getAllergens() {
    return get(`${SERVICE.MENU}/getAllergens?businessId=${this.businessId}`);
  }

  getAllPointBankCoupons() {
    return get(
      `${
        SERVICE.COUPON
      }/getCouponsAvailableForPointBankBalanceTrade?businessId=${
        this.businessId
      }`,
    );
  }

  getDisplayedGroups() {
    return get(`
    ${SERVICE.GROUPS}/getDisplayableBusinessGroups?businessId=${
      this.businessId
    }`);
  }

  getSubscriptionPolicies() {
    return get(
      `${
        SERVICE.COUPON
      }/getSubscriptionPolicies?businessId=${
        this.businessId
      }`,
    );
  }
};
