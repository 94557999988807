// extracted by mini-css-extract-plugin
export var BackToMenu = "index-module--BackToMenu--caf7d";
export var BackToMenuCard = "index-module--BackToMenuCard--7341e";
export var Clickable = "index-module--Clickable--6c232";
export var DesktopGutters = "index-module--DesktopGutters--4dea7";
export var ItemActionsWrapper = "index-module--ItemActionsWrapper--522e1";
export var ItemCarusel = "index-module--ItemCarusel--bc43a";
export var ItemImage = "index-module--ItemImage--52250";
export var Loader = "index-module--Loader--a0098";
export var PriceLine = "index-module--PriceLine--9bce2";
export var SubTotal = "index-module--SubTotal--f23ce";
export var Upsale = "index-module--Upsale--e8013";