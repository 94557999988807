import React from "react";
import { connect } from "react-redux";
import withTranslation from "../hocs/withTranslation";
import ReorderView from "../components/ReorderView";

import { setOrderForReorder, startNewOrder } from "../store/order/actions";
import { openCheckoutView } from "../store/app/actions";
import { loadLatestOrders } from "../store/user/actions";
import {
  loadBranchAvailabiltyIfNeeded,
  loadDeliveryAreas,
} from "../store/locations/actions";
import {
  getBranchesAvailability,
  getStyles,
  getLatestOrders,
} from "../store/selectors";

const ReorderPage = (props) => <ReorderView {...props} />;

const mapStateToProps = (state, props) => {
  const { user, header, order, locations } = state;
  return {
    order,
    user,
    header,
    locations,
    branchesAvailability: getBranchesAvailability(state, props),
    appStyles: getStyles(state, props),
    latestOrders: getLatestOrders(state, props),
  };
};

const mapDispatchToProps = (dispatch, props) => {
  const {
    pageContext: { menuData },
  } = props;

  return {
    loadLatestOrders: () => dispatch(loadLatestOrders()),
    setOrderForReorder: (order, firstAvailableDate) =>
      dispatch(setOrderForReorder(order, firstAvailableDate, menuData)),
    openCheckoutView: () => dispatch(openCheckoutView()),
    loadBranchAvailabiltyIfNeeded: () =>
      dispatch(loadBranchAvailabiltyIfNeeded()),
    loadDeliveryAreas: (params) => dispatch(loadDeliveryAreas(params)),
    startNewOrder: () => dispatch(startNewOrder()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation(ReorderView));
