import classnames from "classnames";
import React from "react";
import * as styles from "./index.module.scss";

export default ({
  children,
  style,
  classNames,
  refEl,
  warning,
  appStyles = {},
  ...props
}) => (
  <div
    ref={refEl}
    className={classnames(
      styles.ErrorBlock,
      warning && styles.WarningMessage,
      classNames,
    )}
    style={{
      ...style,
      ...(warning ? appStyles.WarningBox : appStyles.ErrorBox),
    }}
    {...props}
  >
    {children}
  </div>
);
